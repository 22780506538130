.Container {
  position: relative;
  background-size: contain;
  margin: 0 auto;
  max-width: 100vw;
  width: 414px;
  min-height: 680px;
  max-height: 100vh;
  padding: 80px 10px 20px 20px;
  background-image: url("./img/poster_bg_mobile.jpg");
  background-size: 368px 680px;
  background-repeat: no-repeat;
  background-position: 0 0;
  overflow: visible;
  
  @media screen and (min-width: 768px) {
    background-image: url("./img/poster_bg.jpg");
    background-size: cover;
    width: 851px;
    height: 778px;
    min-height: 778px;
    padding: 65px 25px 50px 50px;
  }
}

.Main {
  color: white;
  z-index: 9;
  position: relative;
  display: block;
}

.Header {
  position: relative;
  display: flex;
  justify-content: space-between;

  figure {
    margin: 0;

    :global(img.brand) {
      width: 90px;
      height: auto;

      @media screen and (min-width: 768px) {
        width: 105px;
      }
    }
  }
}

.Tagline {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 1.27em;
  font-weight: 500;
  color: #473728;
  
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }

  span {
    width: 100px;
    margin-right: 20px;
    text-align: right;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  img {
    height: 27px;
    width: auto;
    
    @media screen and (min-width: 768px) {
      height: 36px;
    }
  }
}

.Article {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 260px;

  @media screen and (min-width: 768px) {
    margin-top: 60px;
    max-width: 550px;
  }
}

.BodyCopyMobile {
  width: 309px;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.BodyCopy {
  width: 757px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.Newsletter {
  margin-top: 15px;
  display: inline-flex;

  // @media screen and (min-width: 1025px) {
  //   margin-top: 8px;
  //   height: 275px;
  // }
}

.Button {
  display: inline-flex;
  align-items: center;
  border: none;
  color: #473728;
  background: #F7F3EF;
  font-size: 11px;
  font-weight: bold;
  line-height: 12px;
  padding: 13px 25px 13px 12px;
  cursor: pointer;

  :global(.material-icons) {
    font-size: 18px;
    margin-right: 6px;
  }

  &:focus {
    outline: none !important;
  }

  @media screen and (min-width: 1025px) {
    // margin-top: 35px;
  }
}

.Sails {
  position: absolute;
  bottom: -120px;
  right: 10px;
  width: 240px;
  height: 240px;
  background-image: url("./img/sail_bg.png");
  background-size: 240px 240px;

  @media screen and (min-width: 768px) {
    bottom: -120px;
    right: -120px;
    width: 480px;
    height: 480px;
  }
}

.Modal {
  background-color: white;
  
  iframe {
    width: 100vw;
    height: 320px;
    border: none;
    
    @media screen and (min-width: 768px) {
      width: 410px;
      height: 290px;
    }
  }
}

.Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
}
