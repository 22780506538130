html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  min-height: 100vh;
  background: #F7F3EF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

p {
  font-size: 16px;
  line-height: 1.625em;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  color: #473728;

  @media screen and (min-width: 1025px) {
    font-size: 16px;
  }
  
  &.display {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.625em;
    margin-bottom: 1.5em;
    
    @media screen and (min-width: 1025px) {
      font-size: 20px;
      line-height: 1.5em;
    }
  }
}

h1 {
  color: #473728;
  font-size: 46px;
  line-height: 1.087em;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0.5em;

  @media screen and (min-width: 768px) {
    font-size: 60px;
    line-height: 1.1667em;
  }
}
